<template>
	<el-dialog :title="'文件返回确认'" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">

					<el-form-item label="返回时间" prop="returnTime">
						<el-date-picker class="selItemInput" value-format="yyyy-MM-dd"
										v-model="dataForm.returnTime" type="date" style="width:300px">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="返回接收人" prop="returnReceiveMan">
						<el-input class="selItemInput" v-model="dataForm.returnReceiveMan" placeholder="返回接收人"
								  style="width:300px">
						</el-input>
					</el-form-item>

		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	export default {
		name: "examination-reply",
		data() {
			return {
				visible: false,
				inline: false,
				dataForm: {
					recNo: '',
					returnReceiveMan: '',
					returnTime: '',
				},
				dataRule: {
					returnTime: [
						{ required: true, message: "请选择返回时间", trigger: "change" }
					],
					returnReceiveMan: [
						{ required: true, message: "请输入返回接收人", trigger: "blur" }
					],
				},
			}
		},
		methods: {
			init(id) {
				this.dataForm = {
					examineTime: '',
					examineSuggestion: '',
				};

				this.dataForm.recNo = id?id:0;
				this.$nextTick(() => {
					this.visible = true;
				})
				if(this.$refs['dataForm'] != undefined) {
					this.$refs['dataForm'].resetFields();
				}
			},
			//表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						this.$http({
							url:  this.$store.state.httpUrl + "/business/fileexamineinfo/returnFile",
							method: "post",
							data: this.$http.adornData(
									{
										returnReceiveMan: this.dataForm.returnReceiveMan,
										returnTime: this.dataForm.returnTime,
										recNo: this.dataForm.recNo,
									}
							)
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			}
		}
	}
</script>
<style scoped>

</style>
